@font-face {
  font-family: "Beausite Classic";
  src: url("BeausiteClassic-Regular.woff2") format("woff2"),
    url("BeausiteClassic-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Beausite Classic";
  src: url("BeausiteClassic-Medium.woff2") format("woff2"),
    url("BeausiteClassic-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Laica A";
  src: url("LaicaA-Italic.woff2") format("woff2"),
    url("LaicaA-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Laica A";
  src: url("LaicaA-MediumItalic.woff2") format("woff2"),
    url("LaicaA-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Unica";
  src: url("NeueHaasUnica-ExtraBold.woff2") format("woff2"),
    url("NeueHaasUnica-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Unica";
  src: url("NeueHaasUnica-Medium.woff2") format("woff2"),
    url("NeueHaasUnica-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Unica";
  src: url("NeueHaasUnica-Regular.woff2") format("woff2"),
    url("NeueHaasUnica-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
